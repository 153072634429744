import { j as o } from "./jsx-runtime-B6kdoens.js";
import x from "styled-components";
import $ from "clsx";
import { D as u } from "./Dot-D2HJedJu.js";
import { BadgeCSS as r } from "./components/Badge/styles.es.js";
import { I as f } from "./Icon-C8jaGjlu.js";
const y = x.div`
  display: inline-flex;
  align-items: center;
  gap: 6px;

  border-radius: 16px;
  border-style: solid;
  border-width: 1px;
  
  &.size-sm {
    ${r.sm}
  }

  &.size-md {
    ${r.md}
  }

  &.size-lg {
    ${r.lg}
  }

  &.color-primary {
    ${r.primary}
  }

  &.color-gray {
    ${r.gray}
  }
  
  &.color-success {
    ${r.success}
  }
  
  &.color-warning {
    ${r.warning}
  }
  
  &.color-error {
    ${r.error}
  }
    
  &.color-blue {
    ${r.blue}
  }
`, D = (t) => {
  const {
    text: c,
    size: n = "sm",
    color: a = "gray",
    withDot: l,
    iconTrailing: s,
    iconPosition: e = "start",
    onClick: m,
    className: d,
    ...p
  } = t, g = $(
    `size-${n}`,
    `color-${a}`,
    d
  ), i = () => s ? /* @__PURE__ */ o.jsx(f, { ...s }) : /* @__PURE__ */ o.jsx(o.Fragment, {});
  return /* @__PURE__ */ o.jsxs(y, { ...p, className: g, onClick: m, children: [
    e === "start" && i(),
    l ? /* @__PURE__ */ o.jsx(u, {}) : void 0,
    c,
    e === "end" && i()
  ] });
};
export {
  D as B
};
