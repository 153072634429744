import { j as t } from "./jsx-runtime-B6kdoens.js";
import m from "styled-components";
import x from "clsx";
import { F as d } from "./FeaturedIcon-Cr5X68Wg.js";
import { O as g } from "./OutlinedIcon-l_LsNoBP.js";
import { T as l } from "./Typography-cVo-_RUq.js";
import "./components/Typography/styles.es.js";
const u = m.aside`
  display: flex;
  align-items: flex-start;

  gap: 16px;
  
  padding: 16px;
  
  border-radius: 12px;
  border-width: 1px;
  border-style: solid;
  
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);

  main {
    display: flex;
    flex-flow: column;
    align-self: center;
    
    gap: 4px;
  }
  
  &.variant-default {
    border-color: ${(e) => e.theme.palette.grays[300].hex};
    background: ${(e) => e.theme.palette.white.hex};
    color: ${(e) => e.theme.palette.grays[700].hex};
  }

  &.variant-primary {
    border-color: ${(e) => e.theme.palette.primary[300].hex};
    background: ${(e) => e.theme.palette.primary[25].hex};
    color: ${(e) => e.theme.palette.primary[700].hex};
  }

  &.variant-grey {
    border-color: ${(e) => e.theme.palette.grays[300].hex};
    background: ${(e) => e.theme.palette.grays[25].hex};
    color: ${(e) => e.theme.palette.grays[700].hex};
  }

  &.variant-success {
    border-color: ${(e) => e.theme.palette.success[300].hex};
    background: ${(e) => e.theme.palette.success[25].hex};
    color: ${(e) => e.theme.palette.success[700].hex};
  }
  
  &.variant-warning {
    border-color: ${(e) => e.theme.palette.warning[300].hex};
    background: ${(e) => e.theme.palette.warning[25].hex};
    color: ${(e) => e.theme.palette.warning[700].hex};
  }
  
  &.variant-error {
    border-color: ${(e) => e.theme.palette.error[300].hex};
    background: ${(e) => e.theme.palette.error[25].hex};
    color: ${(e) => e.theme.palette.error[700].hex};
  }
`, i = {
  default: "info-circle",
  primary: "info-circle",
  grey: "info-circle",
  success: "check-circle",
  warning: "alert-circle",
  error: "alert-circle"
}, k = (e) => {
  const {
    variant: r = "default",
    title: a,
    supportingText: o,
    fullWidth: s,
    className: c,
    // onClose,
    children: n,
    ...h
  } = e, p = x(
    `variant-${r}`,
    {
      "full-width": s
    },
    c
  );
  return /* @__PURE__ */ t.jsxs(u, { ...h, className: p, children: [
    r === "default" && /* @__PURE__ */ t.jsx(
      d,
      {
        iconVariant: i[r]
      }
    ),
    r !== "default" && /* @__PURE__ */ t.jsx(
      g,
      {
        color: r,
        variant: i[r]
      }
    ),
    /* @__PURE__ */ t.jsxs("main", { children: [
      a && /* @__PURE__ */ t.jsx(l, { variant: "text", size: "sm", weight: "bold", as: "span", children: a }),
      o && /* @__PURE__ */ t.jsx(l, { variant: "text", size: "sm", weight: "regular", as: "span", children: o }),
      n
    ] })
  ] });
};
export {
  k as A
};
