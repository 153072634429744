import { j as p } from "./jsx-runtime-B6kdoens.js";
import i from "styled-components";
import { T as m } from "./Typography-cVo-_RUq.js";
import "./components/Typography/styles.es.js";
const n = i(m)`
  &.error {
    color: ${(r) => r.theme.palette.error[500].hex};
  }
`, g = (r) => {
  const {
    size: e = "sm",
    supportingText: t,
    error: o,
    className: s = "",
    ...a
  } = r;
  return /* @__PURE__ */ p.jsx(
    n,
    {
      ...a,
      size: e,
      variant: "text",
      weight: "regular",
      forwardedAs: "span",
      className: `${o ? "error" : ""} ${s}`,
      children: t
    }
  );
};
export {
  g as F
};
