import { j as e } from "./jsx-runtime-B6kdoens.js";
import p from "styled-components";
import x from "clsx";
import { T as l } from "./Typography-cVo-_RUq.js";
import "./components/Typography/styles.es.js";
const d = p.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  gap: 24px;
  
  text-align: center;
  
  max-width: 352px;
  
  div.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    gap: 16px;
  }

  div.texts {
    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 4px;
  }

  div.actions {
    display: flex;
    flex-direction: row;
    align-items: center;

    gap: 12px;
  }
  
  span.title {
    color: ${(t) => t.theme.palette.grays[900].hex};
  }
  
  span.supportingText {
    color: ${(t) => t.theme.palette.grays[600].hex};
  }
`, g = {
  sm: "md",
  md: "lg",
  lg: "xl"
}, h = {
  sm: "sm",
  md: "sm",
  lg: "md"
}, v = (t) => {
  const {
    size: s = "sm",
    illustration: n,
    title: i,
    supportingText: a,
    className: o,
    children: r,
    ...m
  } = t, c = x(
    `size-${s}`,
    o
  );
  return /* @__PURE__ */ e.jsxs(d, { ...m, className: c, children: [
    /* @__PURE__ */ e.jsxs("div", { className: "content", children: [
      n,
      /* @__PURE__ */ e.jsxs("div", { className: "texts", children: [
        i && /* @__PURE__ */ e.jsx(
          l,
          {
            variant: "text",
            size: g[s],
            weight: "bold",
            as: "span",
            className: "title",
            children: i
          }
        ),
        a && /* @__PURE__ */ e.jsx(
          l,
          {
            variant: "text",
            size: h[s],
            weight: "regular",
            as: "span",
            className: "supportingText",
            children: a
          }
        )
      ] })
    ] }),
    /* @__PURE__ */ e.jsx("div", { className: "actions", children: r })
  ] });
};
export {
  v as E
};
