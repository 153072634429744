import { css as r } from "styled-components";
import "../../Typography-cVo-_RUq.js";
import { TypographyCSS as t } from "../Typography/styles.es.js";
const p = {
  sm: r`${t.text.xs} ${t.medium} padding: 2px 8px`,
  md: r`${t.text.sm} ${t.medium} padding: 2px 10px`,
  lg: r`${t.text.sm} ${t.medium} padding: 4px 12px`,
  primary: r`
    border-color: ${(e) => e.theme.palette.primary[200].hex};
    background: ${(e) => e.theme.palette.primary[50].hex};
    color: ${(e) => e.theme.palette.primary[700].hex};`,
  gray: r`
    border-color: ${(e) => e.theme.palette.grays[200].hex};
    background: ${(e) => e.theme.palette.grays[50].hex};
    color: ${(e) => e.theme.palette.grays[700].hex};`,
  blue: r`
      border-color: #B2DDFF;
      background: #EFF8FF;
      color: #175CD3;`,
  success: r`
    border-color: ${(e) => e.theme.palette.success[200].hex};
    background: ${(e) => e.theme.palette.success[50].hex};
    color: ${(e) => e.theme.palette.success[700].hex};`,
  warning: r`
    border-color: ${(e) => e.theme.palette.warning[200].hex};
    background: ${(e) => e.theme.palette.warning[50].hex};
    color: ${(e) => e.theme.palette.warning[700].hex};`,
  error: r`
    border-color: ${(e) => e.theme.palette.error[200].hex};
    background: ${(e) => e.theme.palette.error[50].hex};
    color: ${(e) => e.theme.palette.error[700].hex};`
};
export {
  p as BadgeCSS
};
